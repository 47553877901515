import axios from 'axios'

export async function postResourceFile(game, name, size) {
  const data = {
    game: game,
    name: name,
    size: size,
  }

  return (await axios.post('/portal/resource_zips/', data)).data
}

export async function postResource(game, title, zip, type, description = null) {
  const data = {
    game: game,
    title: title,
    type: type,
    resource_zip: zip,
  }
  if (description != null) {
    data['description'] = description
  }

  return (await axios.post('/portal/resources/', data)).data
}

export async function getResource(resId) {
  return (await axios.get(`/portal/resources/${resId}/`)).data
}

export async function getResources({
  gameId,
  environment = 'dev',
  limit = 10,
  offset,
  title = null,
}) {
  const params = { game: gameId, environment, limit, offset }
  if (title) {
    params['title'] = title
  }
  return (await axios.get('/portal/resources/', { params })).data
}

export async function publishResource(resourceId, form) {
  return (await axios.post(`/portal/resources/${resourceId}/publish/`, form)).data
}

export async function deleteResource(resourceId) {
  return (await axios.delete(`/portal/resources/${resourceId}/`)).data
}

export async function getResourceTypes(gameId) {
  return (await axios.get(`/portal/resources/types/?game=${gameId}`)).data
}
export async function getResourceFiles(resourceId) {
  return (await axios.get(`/portal/resources/${resourceId}/files/`)).data
}

export async function getResourceSet(gameCode, name) {
  return (await axios.get(`/portal/v2/${gameCode}/resource_sets/${name}/`)).data
}

export async function getResourceSets(url, gameCode) {
  if (url === null) {
    url = `/portal/v2/${gameCode}/resource_sets/`
  }
  return (await axios.get(url)).data
}

export async function postResourceSet(gameCode, name, description, type) {
  return (
    await axios.post(`/portal/v2/${gameCode}/resource_sets/`, {
      name: name,
      description: description,
      type: type,
    })
  ).data
}

export async function getResourceSetRevisions(url, gameCode, name) {
  if (url === null) {
    url = `/portal/v2/${gameCode}/resource_sets/${name}/revisions/`
  }
  return (await axios.get(url)).data
}

export async function postResourceSetRevision(
  gameCode,
  name,
  revision,
  diff_zip,
  description = null,
  isFreshRevision = false,
) {
  const data = {
    diff_zip: diff_zip,
    revision: revision,
    is_fresh_revision: isFreshRevision,
  }
  if (description != null) {
    data['description'] = description
  }

  return (await axios.post(`/portal/v2/${gameCode}/resource_sets/${name}/revisions/`, data)).data
}

export async function attachEndpointToResourceSet(gameCode, name, endpointId) {
  return (
    await axios.post(`/portal/v2/${gameCode}/resource_sets/${name}/attach_endpoint/`, {
      endpoint: endpointId,
    })
  ).data
}

export async function detachEndpointToResourceSet(gameCode, name, endpointId) {
  return (
    await axios.post(`/portal/v2/${gameCode}/resource_sets/${name}/detach_endpoint/`, {
      endpoint: endpointId,
    })
  ).data
}

export async function publishResourceSetRevision(gameCode, name, revision, appEnv) {
  return (
    await axios.post(
      `/portal/v2/${gameCode}/resource_sets/${name}/revisions/${revision}/publish/`,
      {
        environment: appEnv,
      },
    )
  ).data
}

export async function recreateResourceSetRevision(gameCode, name, revision) {
  return (
    await axios.post(`/portal/v2/${gameCode}/resource_sets/${name}/revisions/${revision}/recreate/`)
  ).data
}

export async function deleteResourceSetRevision(gameCode, name, revision) {
  await axios.delete(`/portal/v2/${gameCode}/resource_sets/${name}/revisions/${revision}/`)
}
